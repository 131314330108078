import React from "react"
import styled from "styled-components"

const ButtonBlack = ({ name, price, link, handleScroll, bright }) => {
  return (
      <StyledLink
        href={link}
        target="_blank"
        onClick={handleScroll}
        bright={bright}
      >
        {name} {" "}
        {price ? (
          <Discounted><Price>{price}</Price>{' '}<strike>5,000 ₽</strike></Discounted>
        ) : null}

      </StyledLink>

  )
}

const StyledLink = styled.a`
font-family: 'Montserrat', sans-serif;
font-weight: 500;
  display: inline-block;
  background: #FFC600;
  color: #1d1d1d;
  text-decoration: none;
  cursor: pointer;
  border-bottom: none;
  padding: 5px 30px;
  font-size: 1.8rem;
  letter-spacing: 0.1px;
  border: 2px solid #FFC600;

  @media (max-width: 480px) {
    flex-direction: column;
    padding: 10px;
    font-size: 14px;
  }
`

const Price = styled.span`
  border-left: 1px solid #fff;
  display: inline-block;
  position: relative;
  padding-left: 15px;
  margin-left: 15px;
  font-size: 1.7rem;
  font-weight: bold;
  
  @media (max-width: 388px) {
    margin-left: 0;
  }
  strike {
    padding: 0 10px 0 0;
  }
`
const Discounted = styled.span`
  display: inline-block;
  position: relative;
  font-size: 1.7rem;;
`

export default ButtonBlack
