import React from "react"
import styled from "styled-components"

const Intro = ({ startScrolling }) => {
  // const handleScroll = e => {
  //   e.preventDefault()
  //   startScrolling()
  // }
  return (
    <Container>
      <Wraper>
        <Header>
          <h1>
            <CourseTag>
              Курс <span> →</span>
            </CourseTag>
            <CourseTitle>Gatsby Pro</CourseTitle>
          </h1>
          <SubHeader>
            <div className="tagTitle"><mark>Научись создавать современные <span>React</span> приложения на <span>Gatsby JS</span>, используя:</mark></div>
            <div className="tags">
              <span>React JS</span>
              <span>GraphQL</span>
              <span>Javascript</span>
              <span>Headless CMS</span>
              <span>Styled Components</span>
              <span>Serverless Functions</span>
            </div>
          </SubHeader>
        </Header>
      </Wraper>
    </Container>
  )
}
const Container = styled.div`
  position: relative;
  color: #fff;
  border-top: ${props => `10px solid ${props.theme.red}`};
  background-image: url("https://res.cloudinary.com/dlmeqtsfq/image/upload/v1620480587/GATSBY-PRO/gatsby_pro_pattern.png");
  background-size: cover;
  box-shadow: inset 0 -15px 0 -10px rgba(27,27,27,.1),inset 0 15px 0 -10px rgba(27,27,27,.1);
  @media (max-width: 600px) {
    padding-top: 0;
    padding-bottom: 60px;
  }
`

const Wraper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  
  h1 {
    margin-bottom: 4rem;
    position: relative;
    padding: 0;
    color: #fff;
    @media (max-width: 600px) {
      margin-bottom: 2rem;
    }
  }
`

const CourseTag = styled.span`
  display: block;
  max-width: 160px;
  background: rgba(255, 255, 255, 0.33);
  padding: 5px 15px;
  border-radius: 0px;
  color: #1b1b1b;
  font-size: 1.8rem;
  span {
    font-family: "poppins_bold";
  }
  @media (max-width: 600px) {
    font-size: 1.5rem;
    max-width: 90px;
    padding: 2px 10px;
  }
`
const CourseTitle = styled.span`
  font-family: "poppins_bold";
  font-size: 16rem;
  @media (max-width: 900px) {
    font-size: 12rem;
  }
  @media (max-width: 600px) {
    font-size: 7rem;
  }
`

const SubHeader = styled.div`
  .tagTitle {
    max-width: 750px;
    font-size: 2.9rem;
    font-weight: 500;
    margin: 0;
    line-height: 1.4;
    margin-bottom: 4rem;
    mark {
      background: #8942D1;
      color: #fff;
    }
    @media (max-width: 900px) {
      font-size: 2.2rem;
    }
    @media (max-width: 600px) {
      font-size: 1.8rem;
    }
  }

  .tags {
    max-width: 550px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6rem;
    span {
      font-size: 1.8rem;
      border-radius: 3px;
      color: #fff;
      border: 2px solid #fff;
      font-weight: bold;
      display: inline-block;
      margin-right: 8px;
      padding: 1px 8px;
      margin-top: 8px;
      @media (max-width: 900px) {
        font-size: 1.6rem;
      }
      
    }
  }
  span {
    color: #FFC600;
  }
  @media (max-width: 800px) {
    font-size: 2rem;
  }
  @media (max-width: 600px) {
    font-size: 1.8rem;
  }
`

const Header = styled.div`
  padding: 6rem 2rem 26rem;
  @media (max-width: 600px) {
    padding: 3rem 2rem 13rem;
  }

`

export default Intro
