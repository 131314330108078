import React from 'react';
import styled from 'styled-components';

const Info = () => (
  <Container>
    <h2>FAQ</h2>
    <Wrapper>
      <Box>
        <h3>Что необходимо знать перед началом обучения?</h3>
        <p>
          Для успешного прохождения курса вам потребуются <strong>базовые</strong> знания <strong>JavaScript</strong> (на уровне моего <a href="https://stackdev.ru/courses/javascript" rel="noopener noreferrer" target="_blank">курса JS</a>) и <strong>React</strong> (на уровне моего <a href="https://react001.ru" rel="noopener noreferrer" target="_blank">курса ReactJS</a>).
        </p>
      </Box>
      <Box>
        <h3>Кому подойдет этот курс?</h3>
        <p>
          Курс Gatsby Pro отлично подойдет <strong>начинающим</strong> JS разработчикам, которые хотят <strong>улучшить</strong> знания ванильного JS и React, а также изучить новые решения для создания <strong>современных</strong> web-приложений.
        </p>
      </Box>
      <Box>
        <h3>Какие базовые знания я приобрету?</h3>
        <ul>
        <li>
          Пройдя курс вы улучшите знания <strong>ванильного</strong> JS, так как мы будем много работать с данными: преобразовывать <strong>массивы</strong> и работать с <strong>объектами</strong>, используя различные методы и др.</li>
        <li>Вы также улучшите базовые знания <strong>React</strong>. Мы будем использовать встроенные Хуки, а также напишем свой кастомный Хук для отправки данных.</li>
        <li>Вы также разберетесь, что собой представляют <strong>бессерверные</strong> функции, которые сегодня широко используются на <strong>бэкенде</strong> при создании современных приложений.</li>
        <li>Вы расширите свой кругозор, изучив что собой представляет <strong>GraphQL</strong> (современная альтернатива Rest API).</li>
        <li>Узнаете, что собой представляют <strong>прогрессивные</strong> web-приложения (Progressive Web Apps - PWA) и как сильно Gatsby JS <strong>облегчает</strong> работу с Service Workers.</li>
        </ul>
     
      </Box>
      <Box>
        <h3>Что если я пойму, что этот курс не подходит для меня?</h3>
        <p>
          Если вы решите, что по каким-то причинам вам не подходит этот курс - я возвращу ваши деньги.
        </p>
      </Box>
      <Box>
        <h3>Почему мы используем именно Sanity CMS?</h3>
        <p>
          Сегодня на рынке существует <strong>большое</strong> количество систем управления контентом типа <strong>Headless CMS</strong>. Я выбрал <strong>Sanity</strong>, потому что эта CMS написана на React, сочетает <strong>простоту</strong> настройки и возможностей кастомизации.
        </p>
      </Box>
      <Box>
        <h3>Как быстро я получу доступ к курсу после оплаты?</h3>
        <p>
        Вы получите доступ к курсу сразу после оплаты.
        </p>
      </Box>
      <Box>
        <h3>На сколько времени выдается доступ к курсу?</h3>
        <p>
        Доступ к курсу вы получаете навсегда.
        </p>
      </Box>
      <Box>
        <h3>Можно ли скачать курс на компьютер?</h3>
        <p>
        К сожалению, нет. Курс можно смотреть только онлайн в своем личном кабинете.
        </p>
      </Box>
    </Wrapper>
  </Container>
);

export default Info;

const Container = styled.div`
  padding: 4rem 2rem;
  position: relative;
  text-align: center;
  overflow: hidden;
  /* clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 100px), 0 100%); */
  background-color: #f654ae;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23ede9f3' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 100px;
  h2 {
    text-shadow: 7px -3px 0 rgba(0,0,0,0.2);
  }
`;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  position: relative;
`;

const Box = styled.div`
  text-align: left;
  background: #fff;
  margin: 0 auto 3rem;
  padding: 3rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  ul {
    margin: 0;
    text-align: left;
    list-style: square;
    list-style: none;
    padding: 0;
  }
  ul li {
    border-bottom: 1px solid #e0e0e0;
    text-align: left;
    list-style: none;
    line-height: 2;
    padding: 5px 0;
  }
  ul li::before {
    content: "■";
    margin-right: 0.5rem;
    color: #ffc600;
  }
  h3 {
    margin: 0;
    text-transform: uppercase;
    border-bottom: 1px solid #f654ae;
    padding-bottom: 1rem;
    line-height: 1.4;
  }
  @media (max-width: 900px) {
    margin-bottom: 20px;
  }
`;
