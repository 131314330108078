import React from "react"
import styled from "styled-components"

const ButtonNormal = ({ name, price, link, handleScroll }) => {
  return (
      <StyledLink
        href={link}
        target="_blank"
        onClick={handleScroll}
      >
        {name}
      </StyledLink>

  )
}

const StyledLink = styled.a`
display: flex;
align-items: center;
font-family: 'Montserrat', sans-serif;
font-weight: 500;
text-transform: uppercase;
  display: inline-block;
  background: #FFC600;
  color: #1d1d1d;
  text-decoration: none;
  cursor: pointer;
  border-bottom: none;
  padding: 5px 30px;
  font-size: 2rem;
  letter-spacing: 0.1px;
  border: 2px solid #FFC600;
`



export default ButtonNormal
