import React from "react"
import styled from "styled-components"
import VideoIntro from '../VideoIntro'
import ButtonNormal from '../../general/ButtonNormal'


const CourseDemo = ({ startScrolling }) => {
  const handleScroll = e => {
    e.preventDefault()
    startScrolling()
  }
  return (
    <Container>
      <WelcomeContainer>
        <WelcomeWrap>
          <VideoIntro videoId="BYvxpT7TpkY"/>
          <Text>
            <p>
              В рамках курса мы создадим web-приложение на <strong>React</strong>, используя популярный фрэймворк <strong>Gatsby JS</strong>!</p>
              <p>
                Наше приложение - пример того, как выглядит <strong>современный</strong> блог, который использует библиотеку <strong>React</strong>, отлично ранжируется в <strong>поисковых системах</strong>, и где данные (backend) <strong>отделены</strong> от клиентской части (frontend).
            </p>

            <p>
              Приложение будет включать <strong>админ панель</strong> системы управления контентом <strong>Sanity CMS</strong> (написана на React) и
              <strong> клиентскую</strong> часть для отображения страниц постов, категорий и формы отправки email.
            </p>

            <p>
              Посмотреть демо версию приложения можно{" "}
              <a
                href="https://determined-beaver-61b9a1.netlify.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                здесь
              </a>
              .
            </p>
            <ButtonNormal link="/" handleScroll={handleScroll} name="Купить Курс"/>
          </Text>
        </WelcomeWrap>
      </WelcomeContainer>
    </Container>
  )
}

const Container = styled.div`
  margin: 4rem 0 2rem;
  @media (max-width: 900px) {
    margin-bottom: 0;
  }
`

const WelcomeContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0 3rem;
  @media (max-width: 900px) {
    padding: 0;
  }
  h2 {
    color: #000;
  }
`

const WelcomeWrap = styled.div`
  background: #fff;
  margin-top: -240px;
  z-index: 2;
  position: relative;
  padding: 20px;
  box-shadow: 0 0 0 6px rgba(255,255,255,0.1), 0 0 6px rgba(0,0,0,0.18);
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 2rem;
    padding: 40px 20px;
  }
`
const Text = styled.div`
  flex: 1;
  padding-left: 20px;
  text-align: left;
  @media (max-width: 900px) {
    padding-left: 0;
  }
  h2 {
    line-height: 1;
    font-family: "poppins_bold";
    font-size: 4rem;
    margin-top: -10px;
    @media (max-width: 900px) {
      text-align: center;
      margin-top: 1.5rem;
    }
  }
  p {
    line-height: 1.3;
    margin: 0 auto 2rem auto;
  }
`

export default CourseDemo
