const courseVideos = [
  {
    group: 1,
    module: 'Gatsby',
    title: 'Базовая Структура + Маршрутизация',
    list: [
      {
        title: 'Обзор нашего проекта',
        duration: '06:49',
        number: 1,
      },
      {
        title: 'Структура и страницы в Gatsby JS',
        duration: '10:44',
        number: 2,
      },
      {
        title: 'Маршрутизация Gatsby',
        duration: '05:00',
        number: 3,
      },
      {
        title: 'Общий компонент Layout',
        duration: '07:11',
        number: 4,
      },
      {
        title: 'Глобальные стили Styled Components',
        duration: '11:57',
        number: 5,
      },
      {
        title: 'Стили для Header и Footer',
        duration: '09:01',
        number: 6,
      },
      {
        title: 'Добавляем шрифты в Gatsby',
        duration: '04:10',
        number: 7,
      },
    ]
  },
  //
  {
    group: 2,
    module: 'Sanity',
    title: 'Схемы данных + Реляционность',
    list: [
      {
        title: 'Настраиваем Sanity CMS + первый тип данных',
        duration: '14:17',
        number: 8,
      },
      {
        title: 'Добавляем категории и кастомизируем панель управления',
        duration: '13:28',
        number: 9,
      },
      {
        title: 'Связываем посты и категории (настраиваем реляционность)',
        duration: '13:18',
        number: 10,
      },
    ]
  },
  //
  {
    group: 3,
    module: 'Gatsby',
    title: 'Работа с данными + Шаблоны',
    list: [
      {
        title: 'Настраиваем Gatsby JS',
        duration: '07:08',
        number: 11,
      },
      {
        title: 'Настраиваем плагин для получения данных из Sanity',
        duration: '17:13',
        number: 12,
      },
      {
        title: 'Запросы GraphQL в Gatsby JS',
        duration: '11:13',
        number: 13,
      },
      {
        title: 'Создаем компонент для отображения постов',
        duration: '05:07',
        number: 14,
      },
      {
        title: 'Компонент карточка поста',
        duration: '10:56',
        number: 15,
      },
      {
        title: 'Изображения в Gatsby JS',
        duration: '10:25',
        number: 16,
      },
      {
        title: 'Добавляем тестовые данные',
        duration: '05:09',
        number: 17,
      },
      {
        title: 'Статический запрос и фильтрация по категориям',
        duration: '07:39',
        number: 18,
      },
      {
        title: 'Завершаем работу с фильтрацией по категориям',
        duration: '18:58',
        number: 19,
      },
      {
        title: 'Шаблон страниц постов и динамическое создание страниц',
        duration: '20:05',
        number: 20,
      },
      {
        title: 'Завершаем работу над шаблоном страниц постов',
        duration: '18:03',
        number: 21,
      },
      {
        title: 'Шаблон главной страницы',
        duration: '05:46',
        number: 22,
      },
      {
        title: 'Шаблон для отображения страниц категорий',
        duration: '10:17',
        number: 23,
      },
    ]
  },
  {
    group: 4,
    module: 'Gatsby',
    title: 'Пагинация',
    list: [
    {
      title: 'Готовим даные',
      duration: '10:46',
      number: 24,
    },
    {
      title: 'Формируем запрос PageQuery',
      duration: '06:31',
      number: 25,
    },
    {
      title: 'Создаем компонент Pagination',
      duration: '10:22',
      number: 26,
    }
    ]
  },
  {
    group: 5,
    module: 'Gatsby',
    title: 'SEO',
    list: [
      {
        title: 'Пакет React Helmet',
        duration: '03:28',
        number: 27,
      },
      {
        title: 'Создаем SEO компонент',
        duration: '15:09',
        number: 28,
      },
    ]
  },
  {
    group: 6,
    module: 'Gatsby + Node',
    title: 'Бессерверные функции + Формы',
    list: [
      {
        title: 'Создаем Serverless функцию',
        duration: '18:01',
        number: 29,
      },
      {
        title: 'Форма отправки email и кастомный React Хук',
        duration: '12:56',
        number: 30,
      },
      {
        title: 'Передача данных из формы в бессерверную функцию',
        duration: '12:17',
        number: 31,
      },
      {
        title: 'Завершаем создание бессерверной функции',
        duration: '11:12',
        number: 32,
      },
      {
        title: 'Защита формы от спам ботов (технология honeypot)',
        duration: '07:30',
        number: 33,
      },
    ]
  },
  {
    group: 7,
    module: 'Gatsby + Sanity',
    title: 'Размещение на Github & Netlify + Aвтоматизация сборки!',
    list: [
      {
        title: 'Размещение Sanity CMS на хостинге',
        duration: '01:30',
        number: 34,
      },
      {
        title: 'Создание сборки Gatsby JS',
        duration: '01:39',
        number: 35,
      },
      {
        title: 'Размещение Gatsby JS на хостинге Netlify',
        duration: '15:00',
        number: 36,
      },
      {
        title: 'Создаем WebHook Netlify - для автоматизации сборки при изменении данных',
        duration: '07:21',
        number: 37,
      },
    ]
  },
  {
    group: 8,
    module: 'Gatsby',
    title: 'Создаем Progressive Web App',
    list: [
      {
        title: 'Трансформируем React приложение GatsbyJS в Progressive Web App (PWA)',
        duration: '11:05',
        number: 38,
      },
      
    ]
  }


  

];

export default courseVideos;
