import React from "react"
import Layout from "../components/layout"
import Main from "../components/Main"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Курс по Gatsby JS – учимся создавать современное React приложение, используя популярный фрэймворк Gatsby JS." />
    <Main />
  </Layout>
)

export default IndexPage
