import React from 'react';
import styled from 'styled-components';
import courseComments from '../../../course-comments';

const Comments = () => {
  return (
    <Container>
      <Wrapper>
      <h2>Отзывы о моих уроках (<span>Youtube</span>)</h2>

        <Box>
          <ul>
          {courseComments.map((res, i) => {
            return <li key={i}><img alt={`отзыв о курсах - ${i}`} src={res.url}/></li>
            })}   
          </ul>
        </Box>
    
      </Wrapper>
    </Container>
)
}

export default Comments;

const Container = styled.div`
    padding: 4rem 0;
    background: #fff;
`;

const Wrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  h2 {
    color: #000;
    span {
      text-transform: none;
    }
  }
`;

const Box = styled.div`
  margin: 0 auto;
  max-width: 432px;
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    display: block;
    list-style: none;
    padding: 0 10px;
    text-align: left;
    line-height: 1.8;
    margin-bottom: 10px;
    img {
      max-width: 412px;
      height: 100%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      @media (max-width: 430px) {
        width: 100%;
      }
    }
  }

`;
