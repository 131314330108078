const courseComments = [
    {url: 'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1623653047/GATSBY-PRO/Comments/comment_99.png'},
    {url: 'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1623605772/GATSBY-PRO/Comments/comment_77.png'},
    {url: 'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1623567089/GATSBY-PRO/Comments/comment_88.png'},
    {url: 'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1623567089/GATSBY-PRO/Comments/comment_55.png'},
    {url: 'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1623567089/GATSBY-PRO/Comments/comment_33.png'},
    {url: 'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1623567089/GATSBY-PRO/Comments/comment_66.png'},
    {url: 'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1623567089/GATSBY-PRO/Comments/comment_44.png'},
    {url: 'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1623567089/GATSBY-PRO/Comments/comment_11.png'},
    {url: 'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1623567089/GATSBY-PRO/Comments/comment_22.png'},
];

export default courseComments;