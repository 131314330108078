import React from "react"
import styled from "styled-components"
import courseVideos from '../../../course-videos'

const VideosList = () => (
    <Container>
    <Wrap>
      <h2>
        Видео Уроки в HD Качестве
      </h2>
      <div className="modulesList" >
        { courseVideos.map(resItem => {
            return (
                <div className="modulesListItem" key={resItem.group}>
                <h3 className="modulesListItemHeader">
                    <span>{resItem.module}<br></br></span>
                    {resItem.title}
                  </h3>
                  <ul>
                      {resItem.list.map(response => {
                          return (
                            <li key={response.number}>
                            <span className="lessonNumber">{response.number}</span>
                            <span className="lessonTitle">{response.title}</span>
                            <span className="lessonTime">{response.duration}</span>
                        </li>
                          )
                      })}
                 
                  </ul>
            </div>
            )
        })}
      </div>
    </Wrap>
  </Container>
)

export default VideosList;

const Container = styled.div`
  background: #663399;
  margin: 0 auto;
  padding: 4rem 2rem;
  color: #fff;
`

const Wrap = styled.div`
  margin: 0 auto;
  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 7px -3px 0 rgba(0,0,0,0.2);
  }
  .modulesList {
    padding: 0;
    max-width: 800px;
    margin: 0 auto;
    color: #000;
    .modulesListItem {
        margin-bottom: 3rem;
        padding: 3rem;
        display: block;
        background: #fff;
        .modulesListItemHeader {
            border-bottom: 1px solid #000;
            line-height: 1.4;
            font-weight: 900;
            margin: 0;
            text-align: left;
            font-style: italic;
            span {
                text-transform: uppercase;
                font-size: 15px;
                background: #ffc600;
                padding: 2px 7px 2px 5px;
            }
        }

        ul li {
            list-style: none;
            margin: 0;
            padding: 0.3rem 0;
            font-size: 1.6rem;
            border-bottom: 1px solid rgba(0,0,0,0.05);
            .lessonNumber {
                font-weight: 900;
            }
            .lessonTitle {
                margin-left: 10px;
            }
            .lessonTime {
                color: rgba(0,0,0,0.4);
                padding: 4px;
                font-size: 1.2rem;
                margin-left: 2px;
            }
        }
    }
  }
`
// const HD = styled.span`
//   font-size: 7rem;
//   margin: 0 7px 0 2px;
//   @media (max-width: 900px) {
//     font-size: 5rem;
//   }
// `
