import React from 'react';
import styled from 'styled-components';
import { ImPlus } from 'react-icons/im';


const AppTech = () => (
  <Container>
    <h2>Стек Технологий</h2>
    <Wrapper>
      <Box>
        <h3>Frontend</h3>
        <div className="techName">
          <div className="techNameContainer">
              <div className="imgContainer">
                <img src="https://res.cloudinary.com/dlmeqtsfq/image/upload/v1620635693/GATSBY-PRO/react-dark-png.png" alt="курс Gatsby"/>
              </div>
              <span>React.js</span>
          </div>
        </div>
        <div className="techDetails">
          <strong>React</strong> - Javascript библиотека для создания современных пользовательских интерфейсов (UI), которая изменила наш подход к разработке фронтенда.
        </div>
        <ul>
          <li>
           <strong>Gatsby JS</strong> - для маршрутизации, кэширования, обработки изображений и др. полезных фич.
          </li>
          <li>
            <strong>Styled Components</strong> - для создания стилей СSS в современном Javascript.
          </li>
          </ul>
      </Box>

      <Box>
        <h3>Backend</h3>
        <div className="techName">
          <div className="techNameContainer">
              <div className="imgContainer">
                <img src="https://res.cloudinary.com/dlmeqtsfq/image/upload/v1620635693/GATSBY-PRO/sanity-dark-png.png" alt="курс Gatsby"/>
              </div>
              <span>Sanity.io</span>
          </div>
        </div>
        <div className="techDetails">
          <strong>Sanity</strong> — Headless CMS (система управления контентом нового поколения). Отвечает только за управление «чистым» контентом. Бэкенд («тело») при таком подходе не связан с фронтендом («головой»).
        </div>
        <ul>
          <li>
            <strong>Sanity CMS</strong> - для создания и управления нашим контентом (статьи блога).
          </li>
          <li>
            <strong>Serverless Function</strong> - напишем и разместим на хостинге бессерверную функцию для отправки email-ов из Node.js, используя популярный пакет Nodemailer.
          </li>
        </ul>
      </Box>
    </Wrapper>
    <ImPlus />
    <Wrapper>
      <BoxWide>
          <ul>
            <li>
              <strong>GraphQL</strong> - будем использовать для получения данных внутри приложения.
            </li>
            <li>
              <strong>Progressive Web App (PWA)</strong> - трансформируем наше приложение в Прогрессивное Web Приложение (PWA), которое будет доступно в <strong>офф-лайн</strong> режиме!
            </li>
            <li>
              <strong>Пагинация</strong> - создадим собственную пагинацию (перемещение по страницам) с нуля!
            </li>
            <li>
              <strong>React Hooks</strong> - будем использовать стандартные, а также создадим кастомные Хуки для получения данных в нашей форме.
            </li>
            <li>
              <strong>SEO</strong> - создадим собственный SEO компонент для ранжирования в поисковых системах.
            </li>
            <li className="lastListItem">
              <strong>Защита формы от спама</strong> - используем технику Honeypot для защиты нашей формы от spam ботов.
            </li>
          </ul>
      </BoxWide>
    </Wrapper>
  </Container>
);

export default AppTech;

const Container = styled.div`
  padding: 4rem 2rem 8rem;
  position: relative;
  text-align: center;
  @media (max-width: 900px) {
    padding: 4rem 2rem;
  }
  h2 {
    text-shadow: 7px -3px 0 rgba(0,0,0,0.2);
  }
  svg {
    fill: #fff;
    font-size: 50px;
    margin: 2rem 0;
  }
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  background: #fff;
  flex: 1 1 48%;
  max-width: 48%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  .techName {
    padding: 10px;
    background: #193549;
    color: #fff;
    text-transform: uppercase;
    .techNameContainer {
      display: flex;
      justify-content: space-between;
      max-width: 125px;
      font-size: 2rem;
      align-items: center;
      margin: 0 auto;
      .imgContainer {
        max-width: 30px;
        max-height: 30px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .techDetails {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgb(224, 224, 224)
  }
  h3 {
    padding: 20px;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
   font-size: 3rem;
    border-bottom: 2px solid #FFC600;
    color: #000;
  }
  ul {
    padding: 10px 0;
    margin: 0;
  }
  li {
    list-style: none;
    padding: 0 10px;
    text-align: left;
    line-height: 1.8;
  }
  li::before {
    content: '■';
    margin-right: 0.5rem;
    color: #FFC600;
  }
  @media (max-width: 900px) {
    max-width: 100%;
    flex: 1 1 100%;
    margin-bottom: 20px;
  }
`;

const BoxWide = styled(Box)`
  position: relative;
  z-index: 2;
  max-width: 800px;
  flex: none;
  margin: 0 auto;
  ul li {
    border-bottom: 1px solid rgb(224, 224, 224);
    padding: 10px;
  }
  ul .lastListItem {
    border-bottom: none;
  }
  &::before {
    background: #fff;
    transform: rotate(0deg);
    z-index: -1;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    position: absolute;
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  &::after {
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    position: absolute;
    content: "";
    background: #fff;
    transform: rotate(2deg);
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -2;
  }
`;