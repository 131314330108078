import React from "react"
import styled from "styled-components"
import VideoIntro from "../VideoIntro"

const WhatIsGatsby = () => (
  <Container>
    <h2>Что Такое Gatsby JS?</h2>
    <Wrapper>
      <div className="gatsbyInfo">
        <p>
          Gatsby JS - <strong>React фреймворк</strong>, с помощью которого можно быстро собрать и опубликовать современное React-приложение. 
          Главное отличие <strong>Gatsby</strong> от другого популярного React фреймворка <strong>NextJS</strong> - то, что по сути, Gatsby является так называемым генератором <strong>статических</strong> сайтов.</p>  
          <p>То есть все <strong>страницы</strong> проектов на Gatsby создаются в момент <strong>сборки</strong>. Именно благодаря этому, приложения, написанные на Gatsby отличаются <strong>высокой</strong> скоростью и
          отлично ранжируются в <strong>поисковых</strong> системах.
        </p>
        <p>
        Более подробно о Gatsby JS, его <strong>отличиях</strong> от NextJS и о том, как я использую Gatsby JS в своих <strong>собственных</strong> проектах - можно узнать из этого видео.
        </p>
      </div>
      <VideoIntro videoId="xfi7ay2rTTo"/>
    </Wrapper>
  </Container>
)

export default WhatIsGatsby

const Container = styled.div`
  overflow-x: hidden;
  padding: 5rem 0;
  h2 {
    text-shadow: 7px -3px 0 rgba(0,0,0,0.2);
    @media (max-width: 900px) {
      padding: 0 2rem;
  }
  }
`
const Wrapper = styled.div`
  display: flex;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  margin-bottom: 40px;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 2rem;
    margin: 20px;
  }
  h2 {
    color: #000;
  }
  ul {
    margin: 0;
    padding: 10px 0;
  }
  ul li {
    list-style: none;
  }
  ul li::before {
    content: '■';
    margin-right: 0.5rem;
    color: #f654ae;
    font-size: 20px;
  }
  .gatsbyInfo {
    flex: 1;
    padding-right: 20px;
    text-align: left;
  }
`
