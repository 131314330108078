import React from "react"
import styled from "styled-components"

const Teacher = () => (
  <Container>
    <Wrapper>
      <img
        src="https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587554107/REACT001/main_selfie.jpg"
        alt="курс gatsbyjs"
      ></img>
      <Text>
        <h3>Василий Муравьев</h3>
        <p>
          Web разработчик, автор учебной платформы  <a target="_blank" rel="noopener noreferrer" href="https://stackdev.ru">
            stackdev.ru
          </a> и одноименного <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCBKVLoV7qzE_zkX8dMKvcXw">
            youtube канала
          </a>. Участвовал в создании нескольких стартапов в качестве фронтенд разработчика.  
          Более 2-х лет работал <strong>frontend</strong> разработчиком в крупнейшем ритейлере России - <strong>X5 Retail Group</strong>.</p>
         <p>В свободное время участвую в сторонних проектах в качестве <strong>full stack </strong>
          разработчика, где использую React, Node.js + Express и MongoDB.
        </p>
      </Text>
    </Wrapper>
  </Container>
)

export default Teacher

const Container = styled.div`
  padding: 4rem 2rem;
  background: #fff;
  /* background-color: #663399;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 7px; */
  box-shadow: inset 0 -15px 0 -10px rgba(27,27,27,.1),inset 0 15px 0 -10px rgba(27,27,27,.1);
`
const Wrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0 0 6px rgba(255,255,255,0.1), 0 0 6px rgba(0,0,0,0.18);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    display: block;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    flex: 1;
    padding-right: 20px;
    max-width: 225px;
    @media (max-width: 500px) {
      max-width: 100%;
      padding-right: 0;
    }
  }
`

const Text = styled.div`
  flex: 2;
  h3 {
    color: #1b1b1b;
    margin-top: 0;
  }
  p {
    line-height: 1.6;
  }
`
