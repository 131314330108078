import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import ButtonBlack from "../../general/ButtonBlack"

const Pricing = ({ handleChange }) => {
  const pricingRef = useRef(null)

  useEffect(() => {
    handleChange(pricingRef.current)
  })

  return (
    <Container ref={pricingRef}>
      <Wrapper>
        <div className="package first">
          <Info>
            <h3>Курс Gatsby PRO</h3>
            <img
              src="https://res.cloudinary.com/dlmeqtsfq/image/upload/v1620294982/GATSBY-PRO/gatsby-pro-large_1280.png"
              alt="курс react"
            ></img>
            <InfoList>
              <li>
                <strong>38 Видео</strong> с пошаговыми инструкциями в{" "}
                <strong>HD</strong> качестве
              </li>
              <li>
                <strong>Пожизненная</strong> возможность{" "}
                <strong>бесплатно</strong> получать обновления и дополнения к
                курсу
              </li>
              <li>
                Весь <strong>исходный код</strong> для нового и готового
                приложения (файлы и скрипты)
              </li>
              <li>
                Доступ в <strong>закрытый</strong> Telegram канал, где можно
                задать вопрос и обсудить решения
              </li>
            </InfoList>
          </Info>
          <Buy>
            <ButtonBlack
              name="Купить Курс"
              link="https://stackdev.ru/signup-paid?code=003"
              price='2,000 ₽'
            />
          </Buy>
        </div>

        {/* <div className="package second">
          <Info>
            <h3>Доступ ко всем курсам Stackdev</h3>
            <img
              src="https://res.cloudinary.com/dlmeqtsfq/image/upload/v1678718579/2023-Stackdev/subscription-thumbs/subscribe-card-2.jpg"
              alt="подписка stackdev"
            ></img>
            <InfoList>
              <li>
                Весь контент Stackdev в <strong>HD</strong> качестве
              </li>
              <li>
                Весь <strong>исходный код</strong> и учебные материалы
              </li>
              <li>
                Доступ в Telegram каналы <strong>всех курсов</strong>, где можно задать вопрос и обсудить решения
              </li>
              <li>
                Доступ ко всем <strong>обновлениям</strong> и дополнениям материала на Stackdev
              </li>
            </InfoList>
          </Info>
          <Buy>
            <ButtonBlack
              name="Оформить Подписку"
              link="https://stackdev.ru/pricing"
              price='от 750 ₽ / месяц'
            />       
          </Buy>
        </div> */}
      </Wrapper>
    </Container>
  )
}

export default Pricing

const Container = styled.div`
  background: #f7f7f7;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.21);
  padding: 4rem 2rem;
`
const Wrapper = styled.div`
  display: flex;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  justify-content: center;

  .package {
    width: 49%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding-bottom: 30px;
    position: relative;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.03);
  }

  .first {
    margin-right: 1.5%;
  }
  .second {
    margin-left: 1.5%;
  }

  @media (max-width: 980px) {
      flex-wrap: wrap;

      .first {
        margin-right: 0;
        margin-bottom: 22px;
      }
      .second {
        margin-left: 0;
      }

      .package {
        width: 100%;
      }
    }
`;

const Info = styled.div`
  width: 100%;
  background: #fff;
  h3 {
    font-size: 3rem;
    padding: 2rem;
    margin: 0;
    text-align: left;
    color: #000;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
`
const Buy = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 3rem;
  .discountDetails {
    color: #1d9d14;
  }
`
const InfoList = styled.ul`
  margin: 0;
  text-align: left;
  list-style: square;
  list-style: none;
  padding: 0;
  li {
    line-height: 2rem;
    padding: 5px 10px;
    border-bottom: 1px solid #e0e0e0;
    text-align: left;
    list-style: none;
    line-height: 2;
  }
  li::before {
    content: "■";
    margin-right: 0.5rem;
    color: #ffc600;
  }
`
